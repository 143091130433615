import { useCallback } from 'react';

import { useNotifications, useSuspensions } from '@personly/libs-providers';

const useTickets = () => {
  const { createNotification } = useNotifications();
  const { suspensions } = useSuspensions();
  const { createSuspension, removeSuspension } = suspensions;

  const createTicket = useCallback(
    async ({ email, body }, cb) => {
      try {
        createSuspension('CREATE_TICKET');

        const url = new URL(
          '/agon/createTicket',
          process.env.NEXT_PUBLIC_SELF_URL
        );

        const data = new URLSearchParams();
        data.set('body', body);
        data.set('email', email);

        const response = await fetch(url, { method: 'POST', body: data });
        const { result } = await response.json();

        cb(result);
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      } finally {
        removeSuspension('CREATE_TICKET');
      }
    },
    [createNotification, createSuspension, removeSuspension]
  );

  return { createTicket };
};

export default useTickets;
